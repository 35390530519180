import React from 'react';

import PropTypes from 'prop-types';
import type { InferProps } from 'prop-types';

import ActionProps from 'components/prop-types/action';
import getAcfBlockProps from 'components/prop-types/acf-block';

import classNames from 'classnames';

import Action from './action';

enum TimelyButtonsAlignment {
	LEFT = 'left',
	CENTRE = 'centre',
	RIGHT = 'right',
}

const propTypes = {
	...getAcfBlockProps(
		{
			actions: PropTypes.arrayOf(ActionProps).isRequired,

			alignment: function (props, propName, componentName) {
				const validValues = Object.values(TimelyButtonsAlignment);
				const propValue = props[propName] as unknown;

				if (propValue === null || typeof propValue === 'undefined') {
					return null;
				} else if ((validValues as unknown[]).includes(propValue)) {
					return null;
				} else {
					return new Error(`Invalid prop ${propName} supplied to ${componentName}: ${JSON.stringify(props[propName])}`);
				}
			} as PropTypes.Validator<TimelyButtonsAlignment | null | undefined>,
		}
	),
};
type TimelyButtonsPropTypes = InferProps<typeof propTypes>;

const defaultProps = {
	alignment: TimelyButtonsAlignment.CENTRE,
};

const TimelyButtons = Object.assign(
	function TimelyButtons(props: TimelyButtonsPropTypes) {
		const content = props.block?.content;

		const alignment = content?.alignment ?? defaultProps.alignment;

		if (!content) {
			return null;
		}

		return (
			<div
				className={classNames(
					'timely-buttons__layout',
					`timely-buttons__layout--${alignment}`,
				)}
			>
				{content.actions.map((action, i) => {
					return (
						<Action
							key={i}
							action={action}
						/>
					);
				})}
			</div>
		);
	},

	{
		propTypes,
		defaultProps,
	},
);

export default TimelyButtons;
